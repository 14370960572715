import { cn } from "@/lib/utils";
import { SVGProps } from "react";

const LogomarkPaths = () => {
  return (
    <g fill="none" stroke="#38BDF8" strokeLinejoin="round" strokeWidth={3}>
      <path d="M10.308 5L18 17.5 10.308 30 2.615 17.5 10.308 5z" />
      <path d="M18 17.5L10.308 5h15.144l7.933 12.5M18 17.5h15.385L25.452 30H10.308L18 17.5z" />
    </g>
  );
};

export const Logomark: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return <Logo {...props} />;
};

export const Logo: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      className={cn("rounded-lg w-[150px] lg:w-[200px]", props.className)}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      zoomAndPan="magnify"
      viewBox="0 0 318 111.749996"
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <g />
        <clipPath id="a5126f77db">
          <path
            d="M 0.355469 0 L 317.644531 0 L 317.644531 111.296875 L 0.355469 111.296875 Z M 0.355469 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="0266cc2162">
          <path
            d="M 306.53125 0 L 11.28125 0 C 5.226562 0 0.355469 4.871094 0.355469 10.925781 L 0.355469 100.371094 C 0.355469 106.425781 5.226562 111.296875 11.28125 111.296875 L 306.53125 111.296875 C 312.585938 111.296875 317.457031 106.425781 317.457031 100.371094 L 317.457031 10.925781 C 317.457031 4.871094 312.585938 0 306.53125 0 Z M 306.53125 0 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="6febdf3467">
          <path
            d="M 49 35.378906 L 73 35.378906 L 73 42 L 49 42 Z M 49 35.378906 "
            clipRule="nonzero"
          />
        </clipPath>
        <clipPath id="e8220277c5">
          <path
            d="M 38.132812 75 L 83.78125 75 L 83.78125 81 L 38.132812 81 Z M 38.132812 75 "
            clipRule="nonzero"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#a5126f77db)">
        <g clipPath="url(#0266cc2162)">
          <path
            fill="#171710"
            d="M 0.355469 0 L 317.292969 0 L 317.292969 111.296875 L 0.355469 111.296875 Z M 0.355469 0 "
            fillOpacity="1"
            fillRule="nonzero"
          />
        </g>
      </g>
      <path
        strokeLinecap="butt"
        transform="matrix(0, -0.748326, 0.748326, 0, 110.371968, 80.162101)"
        fill="none"
        strokeLinejoin="miter"
        d="M 0.00259934 1.998086 L 59.849733 1.998086 "
        stroke="#f3f5f9"
        strokeWidth="4"
        strokeOpacity="1"
        strokeMiterlimit="4"
      />
      <path
        fill="#f3f5f9"
        d="M 78.777344 68.671875 L 42.960938 68.671875 L 41.597656 74.355469 L 80.140625 74.355469 Z M 78.777344 68.671875 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <g clipPath="url(#6febdf3467)">
        <path
          fill="#f3f5f9"
          d="M 68.867188 35.378906 L 52.871094 35.378906 C 52.597656 35.378906 52.332031 35.421875 52.070312 35.511719 C 51.8125 35.605469 51.574219 35.734375 51.359375 35.902344 C 51.144531 36.074219 50.964844 36.273438 50.816406 36.503906 C 50.667969 36.734375 50.5625 36.984375 50.5 37.25 L 49.585938 41.0625 L 72.15625 41.0625 L 71.238281 37.246094 C 71.171875 36.980469 71.066406 36.730469 70.917969 36.5 C 70.773438 36.269531 70.589844 36.070312 70.375 35.902344 C 70.160156 35.730469 69.925781 35.601562 69.664062 35.511719 C 69.40625 35.421875 69.140625 35.378906 68.867188 35.378906 Z M 68.867188 35.378906 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <g clipPath="url(#e8220277c5)">
        <path
          fill="#f3f5f9"
          d="M 38.945312 75.976562 L 82.792969 75.976562 C 83.019531 75.976562 83.210938 76.058594 83.367188 76.214844 C 83.527344 76.375 83.605469 76.566406 83.605469 76.789062 L 83.605469 80.039062 C 83.605469 80.261719 83.527344 80.453125 83.367188 80.613281 C 83.210938 80.769531 83.019531 80.851562 82.792969 80.851562 L 38.945312 80.851562 C 38.71875 80.851562 38.53125 80.769531 38.371094 80.613281 C 38.210938 80.453125 38.132812 80.261719 38.132812 80.039062 L 38.132812 76.789062 C 38.132812 76.566406 38.210938 76.375 38.371094 76.214844 C 38.53125 76.058594 38.71875 75.976562 38.945312 75.976562 Z M 38.945312 75.976562 "
          fillOpacity="1"
          fillRule="nonzero"
        />
      </g>
      <path
        fill="#f3f5f9"
        d="M 47.640625 49.183594 L 74.097656 49.183594 L 72.539062 42.6875 L 49.199219 42.6875 Z M 47.640625 49.183594 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <path
        fill="#f3f5f9"
        d="M 43.351562 67.046875 L 78.386719 67.046875 L 76.4375 58.925781 L 45.300781 58.925781 Z M 43.351562 67.046875 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <path
        fill="#f3f5f9"
        d="M 47.25 50.804688 L 45.691406 57.300781 L 76.046875 57.300781 L 74.488281 50.804688 Z M 47.25 50.804688 "
        fillOpacity="1"
        fillRule="nonzero"
      />
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(125.645679, 59.042525)">
          <g>
            <path d="M 2.203125 -20.828125 L 6.796875 -20.828125 C 9.390625 -20.828125 11.285156 -20.269531 12.484375 -19.15625 C 13.679688 -18.050781 14.28125 -16.539062 14.28125 -14.625 C 14.28125 -12.394531 13.628906 -10.679688 12.328125 -9.484375 C 11.023438 -8.296875 9.085938 -7.703125 6.515625 -7.703125 L 5.40625 -7.703125 L 5.40625 0 L 2.203125 0 Z M 6.59375 -10.515625 C 8.257812 -10.515625 9.421875 -10.878906 10.078125 -11.609375 C 10.742188 -12.347656 11.078125 -13.238281 11.078125 -14.28125 C 11.078125 -15.394531 10.753906 -16.300781 10.109375 -17 C 9.472656 -17.695312 8.398438 -18.046875 6.890625 -18.046875 L 5.40625 -18.046875 L 5.40625 -10.515625 Z M 6.59375 -10.515625 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(140.654969, 59.042525)">
          <g>
            <path d="M 6.765625 -9.71875 L -0.453125 -20.828125 L 3.34375 -20.828125 L 8.359375 -12.609375 L 13.375 -20.828125 L 17.140625 -20.828125 L 9.9375 -9.65625 L 9.9375 0 L 6.765625 0 Z M 6.765625 -9.71875 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(157.335113, 59.042525)">
          <g>
            <path d="M 2.203125 -20.828125 L 5.40625 -20.828125 L 5.40625 -2.828125 L 11.390625 -2.828125 L 11.390625 0 L 2.203125 0 Z M 2.203125 -20.828125 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(169.569103, 59.042525)">
          <g>
            <path d="M 11.78125 0.28125 C 9.894531 0.28125 8.15625 -0.15625 6.5625 -1.03125 C 4.96875 -1.90625 3.695312 -3.15625 2.75 -4.78125 C 1.800781 -6.40625 1.328125 -8.296875 1.328125 -10.453125 C 1.328125 -12.617188 1.800781 -14.507812 2.75 -16.125 C 3.695312 -17.75 4.96875 -18.992188 6.5625 -19.859375 C 8.15625 -20.722656 9.894531 -21.15625 11.78125 -21.15625 C 13.675781 -21.15625 15.421875 -20.722656 17.015625 -19.859375 C 18.609375 -18.992188 19.875 -17.75 20.8125 -16.125 C 21.757812 -14.507812 22.234375 -12.617188 22.234375 -10.453125 C 22.234375 -8.296875 21.757812 -6.40625 20.8125 -4.78125 C 19.875 -3.15625 18.609375 -1.90625 17.015625 -1.03125 C 15.421875 -0.15625 13.675781 0.28125 11.78125 0.28125 Z M 11.78125 -2.578125 C 13.082031 -2.578125 14.269531 -2.882812 15.34375 -3.5 C 16.425781 -4.113281 17.28125 -5.007812 17.90625 -6.1875 C 18.539062 -7.363281 18.859375 -8.773438 18.859375 -10.421875 C 18.859375 -12.085938 18.539062 -13.507812 17.90625 -14.6875 C 17.28125 -15.863281 16.429688 -16.753906 15.359375 -17.359375 C 14.296875 -17.960938 13.101562 -18.265625 11.78125 -18.265625 C 10.457031 -18.265625 9.257812 -17.960938 8.1875 -17.359375 C 7.113281 -16.753906 6.265625 -15.863281 5.640625 -14.6875 C 5.015625 -13.507812 4.703125 -12.085938 4.703125 -10.421875 C 4.703125 -8.773438 5.019531 -7.363281 5.65625 -6.1875 C 6.300781 -5.007812 7.164062 -4.113281 8.25 -3.5 C 9.34375 -2.882812 10.519531 -2.578125 11.78125 -2.578125 Z M 11.78125 -2.578125 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(193.130862, 59.042525)">
          <g>
            <path d="M 5.296875 -13.484375 L 5.375 -11.21875 L 5.375 0 L 2.203125 0 L 2.203125 -20.84375 L 2.609375 -20.84375 L 14.84375 -7.203125 L 14.734375 -9.578125 L 14.734375 -20.828125 L 17.90625 -20.828125 L 17.90625 0 L 17.484375 0 Z M 5.296875 -13.484375 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(125.645679, 77.89482)">
          <g>
            <path d="M 1.1875 -11.203125 L 4.03125 -11.203125 C 5.632812 -11.203125 6.703125 -10.929688 7.234375 -10.390625 C 7.765625 -9.847656 8.03125 -9.195312 8.03125 -8.4375 C 8.03125 -7.925781 7.914062 -7.445312 7.6875 -7 C 7.46875 -6.550781 7.113281 -6.210938 6.625 -5.984375 C 7.34375 -5.804688 7.875 -5.488281 8.21875 -5.03125 C 8.5625 -4.570312 8.734375 -4.03125 8.734375 -3.40625 C 8.734375 -2.757812 8.628906 -2.191406 8.421875 -1.703125 C 8.222656 -1.210938 7.800781 -0.804688 7.15625 -0.484375 C 6.519531 -0.160156 5.597656 0 4.390625 0 L 1.1875 0 Z M 4.3125 -6.4375 C 5.03125 -6.4375 5.539062 -6.613281 5.84375 -6.96875 C 6.15625 -7.320312 6.3125 -7.765625 6.3125 -8.296875 C 6.3125 -9.265625 5.617188 -9.75 4.234375 -9.75 L 2.859375 -9.75 L 2.859375 -6.4375 Z M 4.609375 -1.4375 C 5.535156 -1.4375 6.160156 -1.617188 6.484375 -1.984375 C 6.804688 -2.359375 6.96875 -2.828125 6.96875 -3.390625 C 6.96875 -3.929688 6.78125 -4.347656 6.40625 -4.640625 C 6.039062 -4.941406 5.488281 -5.09375 4.75 -5.09375 L 2.859375 -5.09375 L 2.859375 -1.4375 Z M 4.609375 -1.4375 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(135.18302, 77.89482)">
          <g>
            <path d="M 5.046875 0.15625 C 3.640625 0.15625 2.617188 -0.265625 1.984375 -1.109375 C 1.359375 -1.953125 1.046875 -3.175781 1.046875 -4.78125 L 1.046875 -11.203125 L 2.75 -11.203125 L 2.75 -4.75 C 2.75 -3.5625 2.925781 -2.710938 3.28125 -2.203125 C 3.644531 -1.691406 4.234375 -1.4375 5.046875 -1.4375 C 5.859375 -1.4375 6.4375 -1.6875 6.78125 -2.1875 C 7.132812 -2.6875 7.3125 -3.539062 7.3125 -4.75 L 7.3125 -11.203125 L 9.03125 -11.203125 L 9.03125 -4.78125 C 9.03125 -3.1875 8.695312 -1.960938 8.03125 -1.109375 C 7.363281 -0.265625 6.367188 0.15625 5.046875 0.15625 Z M 5.046875 0.15625 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(145.268839, 77.89482)">
          <g>
            <path d="M 1.390625 -11.203125 L 3.09375 -11.203125 L 3.09375 0 L 1.390625 0 Z M 1.390625 -11.203125 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(149.748037, 77.89482)">
          <g>
            <path d="M 1.1875 -11.203125 L 2.90625 -11.203125 L 2.90625 -1.53125 L 6.125 -1.53125 L 6.125 0 L 1.1875 0 Z M 1.1875 -11.203125 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(156.329722, 77.89482)">
          <g>
            <path d="M 1.1875 -11.203125 L 3.890625 -11.203125 C 6.140625 -11.203125 7.710938 -10.664062 8.609375 -9.59375 C 9.515625 -8.53125 9.96875 -7.265625 9.96875 -5.796875 C 9.96875 -4.765625 9.773438 -3.8125 9.390625 -2.9375 C 9.003906 -2.070312 8.363281 -1.363281 7.46875 -0.8125 C 6.582031 -0.269531 5.414062 0 3.96875 0 L 1.1875 0 Z M 4.15625 -1.515625 C 5.5625 -1.515625 6.582031 -1.910156 7.21875 -2.703125 C 7.851562 -3.503906 8.171875 -4.507812 8.171875 -5.71875 C 8.171875 -6.894531 7.847656 -7.851562 7.203125 -8.59375 C 6.566406 -9.332031 5.550781 -9.703125 4.15625 -9.703125 L 2.890625 -9.703125 L 2.890625 -1.515625 Z M 4.15625 -1.515625 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(166.811661, 77.89482)">
          <g />
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(170.544327, 77.89482)">
          <g>
            <path d="M 3.765625 0.15625 C 3.148438 0.15625 2.539062 0.0507812 1.9375 -0.15625 C 1.34375 -0.375 0.914062 -0.617188 0.65625 -0.890625 L 1.46875 -2.328125 C 1.675781 -2.140625 2.003906 -1.941406 2.453125 -1.734375 C 2.910156 -1.523438 3.347656 -1.421875 3.765625 -1.421875 C 4.304688 -1.421875 4.742188 -1.546875 5.078125 -1.796875 C 5.421875 -2.046875 5.59375 -2.394531 5.59375 -2.84375 C 5.59375 -3.1875 5.5 -3.484375 5.3125 -3.734375 C 5.132812 -3.992188 4.910156 -4.207031 4.640625 -4.375 C 4.378906 -4.539062 4.003906 -4.75 3.515625 -5 C 2.941406 -5.28125 2.546875 -5.492188 2.328125 -5.640625 C 1.347656 -6.296875 0.859375 -7.207031 0.859375 -8.375 C 0.859375 -9.34375 1.175781 -10.082031 1.8125 -10.59375 C 2.445312 -11.101562 3.253906 -11.359375 4.234375 -11.359375 C 5.273438 -11.359375 6.164062 -11.0625 6.90625 -10.46875 L 6.09375 -9.078125 C 5.894531 -9.285156 5.617188 -9.460938 5.265625 -9.609375 C 4.921875 -9.753906 4.550781 -9.828125 4.15625 -9.828125 C 3.644531 -9.828125 3.242188 -9.710938 2.953125 -9.484375 C 2.671875 -9.253906 2.53125 -8.914062 2.53125 -8.46875 C 2.53125 -8.15625 2.617188 -7.875 2.796875 -7.625 C 2.984375 -7.375 3.21875 -7.15625 3.5 -6.96875 C 3.78125 -6.789062 4.15625 -6.570312 4.625 -6.3125 C 5.082031 -6.050781 5.429688 -5.847656 5.671875 -5.703125 C 5.921875 -5.554688 6.15625 -5.382812 6.375 -5.1875 C 6.664062 -4.914062 6.898438 -4.597656 7.078125 -4.234375 C 7.265625 -3.867188 7.359375 -3.46875 7.359375 -3.03125 C 7.359375 -2.351562 7.203125 -1.773438 6.890625 -1.296875 C 6.578125 -0.816406 6.148438 -0.453125 5.609375 -0.203125 C 5.066406 0.0351562 4.453125 0.15625 3.765625 0.15625 Z M 3.765625 0.15625 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(178.542893, 77.89482)">
          <g>
            <path d="M 4.0625 -5.21875 L 3.359375 -6.8125 L 3.046875 -5.21875 L 2.015625 0 L 0.3125 0 L 2.640625 -11.203125 L 2.8125 -11.203125 L 5.890625 -5.15625 L 6.8125 -3.125 L 7.71875 -5.15625 L 10.734375 -11.203125 L 10.90625 -11.203125 L 13.3125 0 L 11.640625 0 L 10.515625 -5.21875 L 10.171875 -6.8125 L 9.5625 -5.21875 L 6.859375 0 L 6.765625 0 Z M 4.0625 -5.21875 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(192.178555, 77.89482)">
          <g>
            <path d="M 5.28125 -11.21875 L 10.671875 0 L 8.71875 0 L 7.75 -2.1875 L 2.625 -2.1875 L 1.65625 0 L -0.25 0 L 5.125 -11.21875 Z M 4.53125 -6.4375 L 3.3125 -3.734375 L 7.078125 -3.734375 L 5.875 -6.421875 L 5.21875 -7.96875 L 5.1875 -7.96875 Z M 4.53125 -6.4375 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(202.614787, 77.89482)">
          <g>
            <path d="M 6.78125 0 L 4.53125 -4.234375 C 4.0625 -4.210938 3.644531 -4.203125 3.28125 -4.203125 L 2.84375 -4.203125 L 2.84375 0 L 1.1875 0 L 1.1875 -11.203125 L 3.734375 -11.203125 C 5.023438 -11.203125 6.085938 -10.929688 6.921875 -10.390625 C 7.753906 -9.859375 8.171875 -8.992188 8.171875 -7.796875 C 8.171875 -7.046875 7.992188 -6.398438 7.640625 -5.859375 C 7.285156 -5.316406 6.765625 -4.894531 6.078125 -4.59375 L 8.75 0 Z M 3.9375 -5.59375 C 4.925781 -5.59375 5.601562 -5.804688 5.96875 -6.234375 C 6.34375 -6.671875 6.53125 -7.1875 6.53125 -7.78125 C 6.53125 -9.113281 5.644531 -9.78125 3.875 -9.78125 L 2.84375 -9.78125 L 2.84375 -5.59375 Z M 3.9375 -5.59375 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(211.908364, 77.89482)">
          <g>
            <path d="M 3.328125 -9.703125 L 0.453125 -9.703125 L 0.453125 -11.203125 L 7.90625 -11.203125 L 7.90625 -9.703125 L 5.015625 -9.703125 L 5.015625 0 L 3.328125 0 Z M 3.328125 -9.703125 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(220.272586, 77.89482)">
          <g>
            <path d="M 0.796875 2.546875 C 1.203125 1.671875 1.507812 0.863281 1.71875 0.125 C 1.238281 -0.03125 1 -0.378906 1 -0.921875 C 1 -1.242188 1.101562 -1.507812 1.3125 -1.71875 C 1.519531 -1.925781 1.785156 -2.03125 2.109375 -2.03125 C 2.429688 -2.03125 2.6875 -1.910156 2.875 -1.671875 C 3.070312 -1.429688 3.171875 -1.085938 3.171875 -0.640625 C 3.171875 -0.179688 3.03125 0.332031 2.75 0.90625 C 2.46875 1.488281 2.144531 2.035156 1.78125 2.546875 Z M 0.796875 2.546875 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(224.49279, 77.89482)">
          <g />
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(228.225455, 77.89482)">
          <g>
            <path d="M 6.28125 0.109375 C 5.125 0.109375 4.117188 -0.148438 3.265625 -0.671875 C 2.421875 -1.191406 1.773438 -1.882812 1.328125 -2.75 C 0.878906 -3.613281 0.65625 -4.570312 0.65625 -5.625 C 0.65625 -6.726562 0.894531 -7.71875 1.375 -8.59375 C 1.863281 -9.46875 2.539062 -10.148438 3.40625 -10.640625 C 4.28125 -11.128906 5.273438 -11.375 6.390625 -11.375 C 6.878906 -11.375 7.335938 -11.320312 7.765625 -11.21875 C 8.203125 -11.113281 8.5625 -10.992188 8.84375 -10.859375 C 9.132812 -10.722656 9.328125 -10.609375 9.421875 -10.515625 L 8.671875 -8.984375 C 8.046875 -9.535156 7.238281 -9.8125 6.25 -9.8125 C 5.539062 -9.8125 4.898438 -9.640625 4.328125 -9.296875 C 3.765625 -8.953125 3.320312 -8.457031 3 -7.8125 C 2.675781 -7.175781 2.515625 -6.4375 2.515625 -5.59375 C 2.515625 -4.820312 2.65625 -4.117188 2.9375 -3.484375 C 3.226562 -2.859375 3.65625 -2.363281 4.21875 -2 C 4.78125 -1.632812 5.445312 -1.453125 6.21875 -1.453125 C 7.113281 -1.453125 7.976562 -1.703125 8.8125 -2.203125 L 9.3125 -0.765625 C 9.082031 -0.554688 8.671875 -0.359375 8.078125 -0.171875 C 7.484375 0.015625 6.882812 0.109375 6.28125 0.109375 Z M 6.28125 0.109375 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(238.113208, 77.89482)">
          <g>
            <path d="M 6.34375 0.15625 C 5.320312 0.15625 4.378906 -0.078125 3.515625 -0.546875 C 2.660156 -1.023438 1.976562 -1.703125 1.46875 -2.578125 C 0.96875 -3.453125 0.71875 -4.46875 0.71875 -5.625 C 0.71875 -6.789062 0.96875 -7.804688 1.46875 -8.671875 C 1.976562 -9.546875 2.660156 -10.210938 3.515625 -10.671875 C 4.378906 -11.140625 5.320312 -11.375 6.34375 -11.375 C 7.351562 -11.375 8.285156 -11.140625 9.140625 -10.671875 C 10.003906 -10.210938 10.6875 -9.546875 11.1875 -8.671875 C 11.695312 -7.804688 11.953125 -6.789062 11.953125 -5.625 C 11.953125 -4.46875 11.695312 -3.453125 11.1875 -2.578125 C 10.6875 -1.703125 10.003906 -1.023438 9.140625 -0.546875 C 8.285156 -0.078125 7.351562 0.15625 6.34375 0.15625 Z M 6.34375 -1.390625 C 7.039062 -1.390625 7.675781 -1.550781 8.25 -1.875 C 8.832031 -2.207031 9.289062 -2.691406 9.625 -3.328125 C 9.96875 -3.960938 10.140625 -4.722656 10.140625 -5.609375 C 10.140625 -6.503906 9.96875 -7.265625 9.625 -7.890625 C 9.289062 -8.523438 8.835938 -9.003906 8.265625 -9.328125 C 7.691406 -9.660156 7.050781 -9.828125 6.34375 -9.828125 C 5.625 -9.828125 4.972656 -9.660156 4.390625 -9.328125 C 3.816406 -9.003906 3.363281 -8.523438 3.03125 -7.890625 C 2.695312 -7.265625 2.53125 -6.503906 2.53125 -5.609375 C 2.53125 -4.722656 2.703125 -3.960938 3.046875 -3.328125 C 3.390625 -2.691406 3.851562 -2.207031 4.4375 -1.875 C 5.019531 -1.550781 5.65625 -1.390625 6.34375 -1.390625 Z M 6.34375 -1.390625 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(250.789039, 77.89482)">
          <g>
            <path d="M 1.1875 -11.203125 L 3.890625 -11.203125 C 6.140625 -11.203125 7.710938 -10.664062 8.609375 -9.59375 C 9.515625 -8.53125 9.96875 -7.265625 9.96875 -5.796875 C 9.96875 -4.765625 9.773438 -3.8125 9.390625 -2.9375 C 9.003906 -2.070312 8.363281 -1.363281 7.46875 -0.8125 C 6.582031 -0.269531 5.414062 0 3.96875 0 L 1.1875 0 Z M 4.15625 -1.515625 C 5.5625 -1.515625 6.582031 -1.910156 7.21875 -2.703125 C 7.851562 -3.503906 8.171875 -4.507812 8.171875 -5.71875 C 8.171875 -6.894531 7.847656 -7.851562 7.203125 -8.59375 C 6.566406 -9.332031 5.550781 -9.703125 4.15625 -9.703125 L 2.890625 -9.703125 L 2.890625 -1.515625 Z M 4.15625 -1.515625 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(261.270979, 77.89482)">
          <g>
            <path d="M 1.171875 -11.203125 L 6.078125 -11.203125 L 6.078125 -9.703125 L 2.859375 -9.703125 L 2.859375 -6.609375 L 5.453125 -6.609375 L 5.453125 -5.09375 L 2.859375 -5.09375 L 2.859375 -1.515625 L 6.578125 -1.515625 L 6.578125 0 L 1.171875 0 Z M 1.171875 -11.203125 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(268.385897, 77.89482)">
          <g />
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(272.118563, 77.89482)">
          <g>
            <path d="M 1.1875 -11.203125 L 2.90625 -11.203125 L 2.90625 -1.53125 L 6.125 -1.53125 L 6.125 0 L 1.1875 0 Z M 1.1875 -11.203125 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(278.700247, 77.89482)">
          <g>
            <path d="M 1.171875 -11.203125 L 6.078125 -11.203125 L 6.078125 -9.703125 L 2.859375 -9.703125 L 2.859375 -6.609375 L 5.453125 -6.609375 L 5.453125 -5.09375 L 2.859375 -5.09375 L 2.859375 -1.515625 L 6.578125 -1.515625 L 6.578125 0 L 1.171875 0 Z M 1.171875 -11.203125 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(285.815166, 77.89482)">
          <g>
            <path d="M 3.765625 0.15625 C 3.148438 0.15625 2.539062 0.0507812 1.9375 -0.15625 C 1.34375 -0.375 0.914062 -0.617188 0.65625 -0.890625 L 1.46875 -2.328125 C 1.675781 -2.140625 2.003906 -1.941406 2.453125 -1.734375 C 2.910156 -1.523438 3.347656 -1.421875 3.765625 -1.421875 C 4.304688 -1.421875 4.742188 -1.546875 5.078125 -1.796875 C 5.421875 -2.046875 5.59375 -2.394531 5.59375 -2.84375 C 5.59375 -3.1875 5.5 -3.484375 5.3125 -3.734375 C 5.132812 -3.992188 4.910156 -4.207031 4.640625 -4.375 C 4.378906 -4.539062 4.003906 -4.75 3.515625 -5 C 2.941406 -5.28125 2.546875 -5.492188 2.328125 -5.640625 C 1.347656 -6.296875 0.859375 -7.207031 0.859375 -8.375 C 0.859375 -9.34375 1.175781 -10.082031 1.8125 -10.59375 C 2.445312 -11.101562 3.253906 -11.359375 4.234375 -11.359375 C 5.273438 -11.359375 6.164062 -11.0625 6.90625 -10.46875 L 6.09375 -9.078125 C 5.894531 -9.285156 5.617188 -9.460938 5.265625 -9.609375 C 4.921875 -9.753906 4.550781 -9.828125 4.15625 -9.828125 C 3.644531 -9.828125 3.242188 -9.710938 2.953125 -9.484375 C 2.671875 -9.253906 2.53125 -8.914062 2.53125 -8.46875 C 2.53125 -8.15625 2.617188 -7.875 2.796875 -7.625 C 2.984375 -7.375 3.21875 -7.15625 3.5 -6.96875 C 3.78125 -6.789062 4.15625 -6.570312 4.625 -6.3125 C 5.082031 -6.050781 5.429688 -5.847656 5.671875 -5.703125 C 5.921875 -5.554688 6.15625 -5.382812 6.375 -5.1875 C 6.664062 -4.914062 6.898438 -4.597656 7.078125 -4.234375 C 7.265625 -3.867188 7.359375 -3.46875 7.359375 -3.03125 C 7.359375 -2.351562 7.203125 -1.773438 6.890625 -1.296875 C 6.578125 -0.816406 6.148438 -0.453125 5.609375 -0.203125 C 5.066406 0.0351562 4.453125 0.15625 3.765625 0.15625 Z M 3.765625 0.15625 " />
          </g>
        </g>
      </g>
      <g fill="#f3f5f9" fillOpacity="1">
        <g transform="translate(293.813732, 77.89482)">
          <g>
            <path d="M 3.765625 0.15625 C 3.148438 0.15625 2.539062 0.0507812 1.9375 -0.15625 C 1.34375 -0.375 0.914062 -0.617188 0.65625 -0.890625 L 1.46875 -2.328125 C 1.675781 -2.140625 2.003906 -1.941406 2.453125 -1.734375 C 2.910156 -1.523438 3.347656 -1.421875 3.765625 -1.421875 C 4.304688 -1.421875 4.742188 -1.546875 5.078125 -1.796875 C 5.421875 -2.046875 5.59375 -2.394531 5.59375 -2.84375 C 5.59375 -3.1875 5.5 -3.484375 5.3125 -3.734375 C 5.132812 -3.992188 4.910156 -4.207031 4.640625 -4.375 C 4.378906 -4.539062 4.003906 -4.75 3.515625 -5 C 2.941406 -5.28125 2.546875 -5.492188 2.328125 -5.640625 C 1.347656 -6.296875 0.859375 -7.207031 0.859375 -8.375 C 0.859375 -9.34375 1.175781 -10.082031 1.8125 -10.59375 C 2.445312 -11.101562 3.253906 -11.359375 4.234375 -11.359375 C 5.273438 -11.359375 6.164062 -11.0625 6.90625 -10.46875 L 6.09375 -9.078125 C 5.894531 -9.285156 5.617188 -9.460938 5.265625 -9.609375 C 4.921875 -9.753906 4.550781 -9.828125 4.15625 -9.828125 C 3.644531 -9.828125 3.242188 -9.710938 2.953125 -9.484375 C 2.671875 -9.253906 2.53125 -8.914062 2.53125 -8.46875 C 2.53125 -8.15625 2.617188 -7.875 2.796875 -7.625 C 2.984375 -7.375 3.21875 -7.15625 3.5 -6.96875 C 3.78125 -6.789062 4.15625 -6.570312 4.625 -6.3125 C 5.082031 -6.050781 5.429688 -5.847656 5.671875 -5.703125 C 5.921875 -5.554688 6.15625 -5.382812 6.375 -5.1875 C 6.664062 -4.914062 6.898438 -4.597656 7.078125 -4.234375 C 7.265625 -3.867188 7.359375 -3.46875 7.359375 -3.03125 C 7.359375 -2.351562 7.203125 -1.773438 6.890625 -1.296875 C 6.578125 -0.816406 6.148438 -0.453125 5.609375 -0.203125 C 5.066406 0.0351562 4.453125 0.15625 3.765625 0.15625 Z M 3.765625 0.15625 " />
          </g>
        </g>
      </g>
    </svg>
  );
};
